.previous_round {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #fff;
  cursor: pointer;
  padding: 6px 14px;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 100%;
}

.next_round {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  border: none;
  padding: 2px;
  cursor: pointer;
  padding: 6px 14px;
  border-radius: 100%;
}

/* Buttons */

.go_to_cart {
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.go_to_cart:hover {
  background-color: #0056b3;
}


.quantity_controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.quantity_controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.quantity_controls button:hover {
  background-color: #0056b3;
}

.quantity_controls span {
  font-size: 18px;
  font-weight: bold;
}



/* Card */

.heading {
  text-align: center;
  color: #FF9933;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
}

.filterContainer {
  text-align: left;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 400px;
}

.filterLabel {
  font-size: 18px;
  font-weight: bold;
  color: #138808;
  width: 100%;
}

.dropdown {
  position: relative;
  width: 100%;
  /* Increased width */
  border-radius: 12px;
  background: linear-gradient(45deg, orange, green);
  color: white;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  z-index: 9;
}

.dropdown:hover {
  /* transform: scale(1.05); */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.dropdownSelected {
  font-size: 16px;
  font-weight: bold;
}

.dropdownList {
  position: absolute;
  width: 100%;
  background: white;
  margin-top: 3px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 1000;
  right: 0.2px;
}

.dropdownItem {
  padding: 5px;
  font-size: 16px;
  color: black;
  /* transition: background 0.3s ease-in-out; */
  cursor: pointer;
}

.dropdownItem:hover {
  background: orange;
  color: white;
}

.dropdownItem.active {
  background: green;
  color: #e6ffe6;
  font-weight: bold;
}

.subheading {
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #138808;
  text-transform: uppercase;
}



.cartContainer {
  width: 80%;
  margin: auto;
  padding: 20px;
}

.emptyCart {
  text-align: center;
  font-size: 18px;
}

.productImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 15px;
}

.productName {
  font-size: 20px;
  font-weight: bold;
  color: #FF9933;
}

.size {
  font-size: 16px;
  color: gray;
}

.totalContainer {
  text-align: right;
  margin-top: 20px;
  background-color: #e6ffe6;
  padding: 30px;
}

.total {
  font-weight: bold;
  color: #138808;
  font-size: 24px;
}

.checkoutBtn {
  background-color: #FF9933;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  margin-top: 20px;
}

.cartButtonContainer {
  position: absolute;
  top: 10px;
  /* Adjust as needed */
  right: 2px;
  display: flex;
  gap: 10px;
}

.bill {
  color: #000080;
  font-size: 18px;
}


/* *********************************Info Button************************************** */

.infoIcon {
  font-size: 20px;
  padding: 5px 12px 0px 12px;
  background-color: #FF9933;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  transition: transform 0.2s ease-in-out;
}

.infoIcon:hover {
  transform: scale(1.2);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Content Box */
.modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-height: 500px;
  /* Set fixed height */
  overflow-y: auto;
  /* Enable vertical scroll */
  text-align: center;
  position: relative;
}

/* Scrollbar Styling */
.modalContent::-webkit-scrollbar {
  width: 6px;
}

.modalContent::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

/* Mart Details Content */
.martDetails img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin: 10px 0;
}

.closeButton {
  margin-top: 10px;
  padding: 10px 15px;
  background: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: black;
  transition: transform 0.2s ease-in-out;
}

.closeIcon:hover {
  transform: scale(1.2);
  color: red;
}

.orderStatus {
  display: flex;
}


.martClosedContainer {
  text-align: center;
  margin-top: -90px;
}

.martClosedGif {
  width: 50%;
  /* Adjust size */
  height: auto;
}


@keyframes swing {
  0% {
    transform: rotate(2deg);
  }

  50% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(2deg);
  }
}

.martClosedGif {
  animation: swing 2s infinite alternate ease-in-out;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 2px;
  border: 2px solid #FF9933;
  background-color: #e6ffe6;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  margin-bottom: 25px;
  margin: 2px;
}

/* .innerCard {
  justify-content: space-between;
} */

.card:hover {
  transform: scale(1.05);
}

.carousel_container {
  position: relative;
  width: 100%;
  height: auto;
}

.product_img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.prev_btn,
.next_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50%;
}

.prev_btn {
  left: 5px;
}

.next_btn {
  right: 5px;
}

.product_name {
  font-size: 1rem;
  color: #FF9933;
  font-weight: bold;
  margin-top: 10px;
}

.description {
  font-size: 0.9rem;
  color: #555;
  min-height: 60px;
  max-height: 60px;
  text-align: left;
  padding: 5px;
}

.stock {
  font-size: 0.9rem;
  color: #27ae60;
  font-weight: bold;
}

.price {
  font-size: 1.1rem;
  font-weight: bold;
  color: #138808;
}

.quantity_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.quantity_btn {
  background: #138808;
  color: #fff;
  border: none;
  padding: 3px 10px;
  margin: 0 5px;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
}

.quantity {
  font-size: 1.2rem;
  font-weight: bold;
}

.add_to_cart {
  background: #f39c12;
  color: white;
  border: none;
  padding: 5px 12px;
  font-size: 1rem;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
}

.add_to_cart:hover {
  background: #e67e22;
}

.innerCard {
  position: relative;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #FF9933;
  padding: 2px;
  background-color: #e6ffe6;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.innerCard:hover {
  transform: scale(1.05);
}

/* Responsive Fixes */
@media (max-width: 576px) {

  .product_name {
    font-size: 0.9rem;
  }

  .description {
    font-size: 0.8rem;
    text-align: left;
  }

  .stock,
  .price,
  .size {
    font-size: 0.70rem;
  }

  .productName {
    font-size: 0.75rem;
  }

  .quantity_btn {
    padding: 4px 8px;
    font-size: 1rem;
  }

  .add_to_cart {
    font-size: 0.9rem;
    padding: 6px 10px;
  }
}

.cartItem {
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  /* Ensures items wrap when needed */
  border-bottom: 1px solid #ddd;
}

.detailsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  /* Allows elements to stay in a row while wrapping when needed */
}

.productImage {
  flex: 0 0 60px;
  /* Fixed width for image */
}

.cartImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.details {
  flex: 1;
  min-width: 120px;
  /* Prevents content from shrinking too much */
}

.quantityContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quantityBtn {
  padding: 5px 8px;
  border: none;
  background: #138808;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.removeBtn {
  padding: 5px 10px;
  background-color: #FF9933;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;
  /* Prevents text from breaking */
}

/* Responsive Design */
@media (max-width: 737px) {

  .card {
    transition: none;
    margin: 0px;
  }

  .card:hover {
    transform: none;
  }

  .quantity {
    font-size: 0.75rem;
    font-weight: bold;
  }

  .quantityBtn {
    padding: 1px 3px;
    border: none;
    background: #138808;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }

  .cartContainer {
    width: 100%;
    padding: 0;
  }

  .removeBtn {
    padding: 3px 6px;
    background: #FF9933;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    /* Prevents text from breaking */
  }

  .cartItem {
    flex-wrap: nowrap;
    /* Prevents full stacking */
    overflow-x: auto;
    /* Enables horizontal scrolling if needed */
    padding: 10px 5px;
  }

  .detailsRow {
    flex-wrap: nowrap;
  }

  .productImage {
    flex: 0 0 50px;
  }

  .cartImage {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  .details {
    min-width: 100px;
  }

  .quantityContainer {
    flex-shrink: 0;
  }

  .removeBtn {
    flex-shrink: 0;
  }
}


/* .details {
  flex: 1;
  justify-content: space-between;
}

.detailsRow {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.productImage {
  flex: 0 0 auto;
  margin-right: 20px;
}

.cartimage {
  width: 120px;
  height: 120px;
  object-fit: cover;
} */