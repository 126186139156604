/*******************BasicfCards*************/
.basic_listing_info:nth-child(even) {
    /* padding-left: 0px; */
}

.basic_listing_info:nth-child(odd) {
    /* padding-right: 1px; */
    /* padding-left: 0; */
}

.basic_listing_info {
    padding-left: 8px;
    padding-right: 8px;
}



.listing_info_box_basic {
    /* border: 1px solid; */
    height: auto;
    margin-bottom: 10px;
    margin-left: .25rem;
    margin-right: .25rem;
    background: #ffffff;
    /* border-color: #cccccc; */
}

.homeVIdeo {
    margin-left: 80px;
}

.viewed_mark {
    position: absolute;
    right: 5px;
    bottom: 8px;
    color: #138808;
    background: white;
    border-radius: 50%;
    border: solid 1px #fff;
}

.viewed_right {
    right: 20px;
}

.listing_marginBottom {
    margin-bottom: 10px;
}

/*********************************************/

@media screen and (min-device-width: 360px) and (max-device-width: 567px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 0px; */
        /* padding-left: 17px; */
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 0px; */
        /* padding-left: 17px; */
    }

    .listing_info_box_basic {
        min-width: 400px;
        width: 400px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }

    .viewed_mark_home {
        position: absolute !important;
        bottom: 6px !important;
        color: #138808;
        background: white;
        border-radius: 50%;
        border: solid 1px #fff;
    }

   

    .home_thumb_image_basic {
        border-right: 1px solid;
        background: #ffffff;
        border-color: #cccccc;
        height: 150px !important;
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
    }
    
    .home_thumb_image_basic_img {
        object-fit: cover;
        height: 150px !important;
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
    }

    .home_Desc {
        padding: 0px !important;
        margin-left: 0px !important;
    }

    .home_link {
        /*cursor: pointer;*/
        width: fit-content;
        font-size: 0.8rem !important;
        color: #FF9933;
        line-height: 14px;
        padding-left: 10px !important;
    }
    
    .basic_home_listing_info_box {
        overflow: hidden;
        margin-bottom: 5px;
        font-size: 10px !important;
        line-height: 12px;
        padding-left: 10px !important;
    }
    
    .basic_home_listing_info_box_desc {
        overflow: hidden;
        height: 25px;
        margin-bottom: 5px;
        line-height: 14px;
        font-weight: 700;
        font-size: 10px !important;
        padding-left: 10px !important;
        font-style: italic;
    }

}

@media screen and (min-device-width: 568px) and (max-device-width: 639px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 28px; */
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 0px; */
        /* padding-left: 28px; */
    }

    .listing_info_box_basic {
        min-width: 516.5px;
        width: 516.5px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }

}

@media screen and (min-device-width: 640px) and (max-device-width: 767px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 12px; */
        /* padding-right: 0px; */
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 0px; */
        /* padding-left: 12px; */
    }

    .listing_info_box_basic {
        min-width: 588px;
        width: 588px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }
    
}

@media screen and (min-device-width: 768px) and (max-device-width: 999px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 8px; */
        min-width: 725px;
        /* padding-right: 0px; */
    }

    .viewed_mark_home {
        position: absolute;
        bottom: 5px !important;
        color: #138808;
        background: white;
        border-radius: 50%;
        border: solid 1px #fff;
    }
    
    .viewed_right_home {
        right: 20px !important;
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 0px;
        padding-left: 8px; */
        min-width: 725px;
    }

    .listing_info_box_basic {
        min-width: 725px;
        width: 725px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }

}

@media screen and (min-device-width: 1000px) and (max-device-width: 1279px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 1px; */
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 1px; */
    }

    .listing_info_box_basic {
        min-width: 480px;
        width: 480px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }
    
}

@media screen and (min-device-width: 1280px) and (max-device-width: 1326px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 1px; */
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 1px; */
    }

    .listing_info_box_basic {
        min-width: 602.5px;
        width: 602, 5px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }
    
}

/**********************Basic Video HomePage Cards**************************/

.listing_info_box_basic_home {
    /* border: 1px solid; */
    /* height: auto; */
    margin-bottom: 10px;
    margin-left: .25rem;
    margin-right: .25rem;
    /* background: #ffffff; */
    /* border-color: #cccccc; */
}

.home_thumb_image_basic {
    /* border-right: 1px solid; */
    background: #ffffff;
    border-color: #cccccc;
    /* height: 200px;
    width: 200px;
    max-width: 200px;
    min-width: 200px; */
}

.home_thumb_image_basic_img {
    object-fit: cover;
    /* height: 200px;
    width: 200px;
    max-width: 200px; */
    /* min-width: 200px; */
}

.home_Desc {
    padding: 7px !important;
    background-color: white;
}

.home_link {
    /*cursor: pointer;*/
    width: fit-content;
    font-size: 1.20rem;
    color: #FF9933;
    /* padding-left: 95px; */
}

.basic_home_listing_info_box {
    overflow: hidden;
    margin-bottom: 5px;
    font-size: 14px;
    /* padding-left: 95px; */
}

.basic_home_listing_info_box_desc {
    overflow: hidden;
    height: 50px;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px;
    /* padding-left: 95px; */
    font-style: italic;
}

.name_span {
    font-style: normal;
}

.viewed_mark_home {
    position: absolute;
    right: 5px;
    bottom: 8px;
    color: #138808;
    background: white;
    border-radius: 50%;
    border: solid 1px #fff;
}

.viewed_right_home {
    right: 20px;
}


.image_container {
    position: relative;
    display: inline-block;
    margin-top: 10px;
}

.preview_image {
    width: 200px;
    height: auto;
    display: block;
    border-radius: 5px;
}

.cancel_btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.8);
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
}

.cancel_btn:hover {
    background: rgba(255, 0, 0, 1);
}


.addbtn {
    background-color: #FF9933;
    color: #fff;
}

.addbtn:hover {
    background-color: #138808;
}

.photoAdd {
  text-align: center;
   
}