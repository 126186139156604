body {
    height: auto;
    width: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.875rem !important;
}

.App {
    position: relative;
    /* min-height: 100vh; */
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/*--------------------------------------------------------------
                      Header Section
--------------------------------------------------------------*/

/**
 * These are the styles for the header component.
 */

.middle_strip .LeaderBoard_Add h5 {
    padding: 28px 5px;
    margin: auto;
}

.middle_strip .right_side_btn li {
    border-right: 1px solid;
    padding: 0 10px;
    margin: auto;
    line-height: normal;
}

.welcome_guest {
    display: none;
}

.middle_strip .post_ad_btn button, .contact_subbtn, .contact_subbtn:hover {
    font-weight: bold;
}

/*** End of Header Section ***/

.left_lr {
    padding-top: 30px !important;
}

.items_list .card_header:hover .img_description {
    visibility: visible;
    opacity: 1;
}

.container {
    padding-top: 2rem;
}

.custom-container {
    margin: 50px 0;
}

.custom-container .custom-row {
    padding-bottom: 20px;
}

.custom-container .form-control {
    margin-bottom: 15px;
}

.container .custom-row .title {
    width: 15%;
}

.container .custom-row .description {
    width: 40%;
}

.container .loction-infield .address {
    width: 30%;
    padding: 2.375rem .2rem;
}

.container .loction-infield .location {
    width: 15%;
    padding: .175rem .2rem;
}

.container .openhours .hour-open-close .op-hour .openhours-row {
    margin-bottom: 1.5em;
}

.custom-container .custom-row label {
    padding-bottom: 5px;
}

.topbar-nav {
    border-top: 5px solid;
    padding: 0 !important;
    margin-bottom: 10px;
}

.topbar-nav .site-description {
    float: left;
}

.topbar-nav .top-rightcontent, .dashboardData {
    float: right;
}

.topbar-nav .social-icon span {
    padding-right: 10px;
}

.topbar-nav .social-icon {
    display: inline;
}

.site-logo {
    width: 20%;
}

.site-logo img {
    vertical-align: middle;
    max-width: 100%;
}

.login-button .toggle-button {
    padding: 10px 18px;
    border-radius: 2px;
    text-decoration: none;
}

.sbt_btn {
    padding-left: 31px;
    padding-top: 15px;
}

.left {
    padding-left: 5px;
}

.card_size {
    width: 300px;
    float: left;
    margin: 20px;
    cursor: pointer;
}

.card .car_img_height {
    height: 250px;
}

.products_container {
    margin-left: 130px;
}

.image_hover .img-fluid {
    max-width: 100%;
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.search_inputs {
    padding: 10px 5px;
    margin: auto;
}

.search_inputs .form-group {
    margin: auto;
    vertical-align: middle;
}

.resource_details .resource_title, .resource_details .resource_rating {
    display: inline-block;
    vertical-align: middle;
    margin: auto 10px;
}

.resource_details input[type="text"] {
    outline: none;
    border: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.resource_details .resource_social_icons {
    list-style: none;
    vertical-align: middle;
    text-align: right;
    margin: auto
}

.resource_details .resource_social_icons li {
    display: inline-block;
    padding: 0 2px;
}

.resource_details {
    padding: 45px 0;
    border-bottom: 1px solid;
}

.resource_details .resource_subtitle {
    padding-left: 13px;
    margin-top: 8px;
}

.resource_details .resource_social {
    vertical-align: middle;
    margin: auto;
}

.resource_details .resource_social .resource_social_share {
    text-align: right;
    margin-top: 8px;
}

.gallery_images {
    margin-top: 60px;
}

.gallery_images .carousel-inner img {
    width: 100%;
    height: 100%;
}

.gallery_images .gallery_description {
    margin: 20px 0;
    padding: 0 15px;
}

.gallery_images .opening_hours {
    padding: 20px;
    margin: 1px;
}

.gallery_images .opening_hours_label {
    padding: 10px 20px;
}

.gallery_images .opening_hours_label .calender_icon {
    border-radius: 10px;
}

.gallery_images .address_table .address_table_td {
    width: 30%;
}

.gallery_images .address_label {
    padding: 10px 20px;
}

.image_hover {
    position: relative;
    width: 100%;
    padding-top: 40px;
}

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.image_hover:hover .image {
    opacity: 0.3;
}

.image_hover:hover .middle {
    opacity: 1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.grid_container {
    padding: 0 2%;
}

.low_padding {
    padding: 5px;
    /* padding-left: 5px;
    padding-right: 5px; */
}

.no_padding {
    padding: 0 !important;
}

.no_margin {
    margin: 0 !important;
}

.map_marker {
    font-size: 2rem;
    position: absolute;
}

/* Add Post CSS*/

.custom_upload_field {
    height: calc(1.5em + .75rem + 5px);
    line-height: 1.8;
    z-index: 0;
}

.custom_upload_field::after {
    height: auto;
    line-height: 1.8;
    background-color: #000080;
    color: white;
}

.openhours .ant-time-picker {
    width: 200px;
}

.card_section {
    margin-bottom: 15px;
}

.openhours .table-responsive {
    padding: 0px;
}

.classified_add {
    margin-top: 15px;
}

.location label {
    font-weight: bold;
}

.locateMobilePopup {
    display: none;
}

@media  screen and (max-width: 900px) {
    .locateMobilePopup {
        display: block;
    }
    .locateDesktopPopup {
        display: none;
    }
}

.content, .right_side_bar, .listings, .container {
    margin: 0 auto;
    /* padding: 5px; */
}

.content, .right_side_bar, .listings {
    background-color: #fff !important;
}

.listings_footer {
    margin: 0 auto;
}

.content, .right_side_bar, .container {
    display: inline-block;
    max-width: 985px;
    /* padding: 0px; */
}

.regiscontent {
    display: inline-block;
    /* padding: 5%; */
    min-width: 100%;
    min-height: fit-content;
    background-color: #000080;
}

.pageContent {
    background-color: #fff;
}

.clipContent {
    height: 100vh; /* Set a fixed height, adjust as needed */
    overflow-x: hidden; /* Enable vertical scrolling if content overflows */
    background-color: #000080;
    /* overflow-y: hidden; */
}

.formContent {
    background-color: #000080;
    height: auto;
    overflow: hidden;
}

.formContentLogin {
    background-color: #000080;
    height: 100vh;
}

.header_container {
    display: inline-block;
    min-width: 100%;
    padding: 0px;
    padding-top: 0.7rem;
    padding-bottom: 0.5rem;
}

.right_side_bar {
    min-width: 336px;
    /* background: #fffaf5; */
}

.listings>.content {
    min-height: 100vh;
    overflow: hidden;
}

.items_list .img_description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    margin: auto;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
}

.Half_Page_Ad {
    width: 450px;
    height: 622px;
    margin: 15px 10px 0 -90px;
}

.BillBoard_Ad img, .Large_Rectangle_Ad img {
    border: 1px solid;
}

.search_button_row {
    text-align: center;
    margin: auto;
}

.items_list .card_header p.img_description {
    font-size: .8em;
}

/*Nav Tabs for LookUp*/

.listing_type_tabs {
    margin: 5px 0;
}

.listing_type_tabs_mobile {
    /* margin-top: 40px; */
    padding-bottom: -30px;
}

.nav-tabs .nav-link {
    border: 2px solid;
    border-radius: 20px;
    font-weight: 600;
    transition: .5s ease;
}

.no_data {
    text-align: center;
    margin: 10% auto;
}

.single_listing {
    margin-bottom: 15px;
}

/* Contact Page css */

.contact_maincontent .contact_label {
    padding: 5px 0;
    font-weight: 600;
}

.contact_maincontent .btn_sendmail {
    margin: 10px 0;
}

.contact_rply {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    vertical-align: middle;
    padding: 15%;
}

.contact_form {
    position: relative;
}

.contact_rply p {
    font-weight: 700;
}

/*Contact Slider CSS */

/*.slide_image{*/

/*  height: 60vh;*/

/*}*/

/*login page*/

.counterstayle {
    border-width: 0;
}

.add_button button, .add_button button:hover, .add_button button:active {
    text-decoration: none;
    padding-left: 0;
    padding-right: 0;
}

.hide {
    display: none;
}

.color_indicators {
    margin-left: 15px;
}

.color_indicators span {
    padding: 5px;
}

.range_input input {
    width: 100%;
    height: fit-content;
    padding: 2px 0;
    margin: auto;
    vertical-align: sub;
}

.article_video:focus {
    outline: none;
}

.listing_preview {
    margin-top: 1.2rem;
}

.article_img_tag {
    padding: 1.2em;
    text-align: center;
}

.article_img_tag_mob { 
    padding-top: 45px;
    padding-bottom: 45px;
    text-align: center;
}

.photo_container {
    text-align: center;
}

.publisher {
    margin-top: 15px;
    font-size: 0.9rem;
    margin-bottom: -5px;
}

.addpost_section .article_remove, .post_section .image_label {
    margin-top: 0;
}

.listing_preview .table td, .listing_preview .table th {
    padding-left: 0;
}

.listing_preview .table span, .btn_gray, .btn_gray:active, .btn_gray:hover {
    font-weight: bold;
}

.newsart_main .insert_tab .nav-item {
    padding-right: 10px;
}

.ReactGridGallery {
    max-height: 370px;
    width: 300px;
    /*overflow: hidden;*/
    text-align: right;
    margin: auto;
}

.ReactGridGallery_tile-viewport img {
    object-fit: cover;
}

.gallery_over_lay {
    font-size: larger;
    font-weight: bold;
    text-align: center;
    padding-top: 32%;
}

.ReactGridGallery_tile .ReactGridGallery_tile-overlay {
    -moz-box-shadow: inset 0 0 5px #000000;
    -webkit-box-shadow: inset 0 0 5px #000000;
    box-shadow: inset 0 0 5px #000000;
}

.wrapper-class {
    border: 1px solid;
}

.editor-class {
    padding: 10px;
    font-size: 18px;
}

input.no_padding, input.no_padding:focus {
    padding: 0;
}

.premium_time_picker .ant-time-picker-input, .premium_time_picker .ant-time-picker-input:focus {
    border: none !important;
    padding: 0 !important;
}

.premium_time_picker {
    width: 100% !important;
}

.premium_image_upload .fileContainer {
    box-shadow: none;
    margin: auto;
    padding: 0;
}

.premium_image_upload .fileContainer .chooseFileButton {
    margin: auto auto 10px auto;
    font-weight: bold;
}

.premium_banner {
    width: 100% !important;
    height: auto !important;
}

.premium_banner_mobile {
    width: 100%;
    height: auto !important;
}

.slider_mobile {
    display: none;
}

@media screen and (max-width: 720px) {
    .slider_desktop {
        display: none;
    }
    .slider_mobile {
        display: block;
    }
}

.item .close {
    position: absolute;
    top: 15px;
    right: 45px;
    display: none;
    cursor: pointer;
}

.item .close i {
    -webkit-box-shadow: 1px 5px 10px 3px #000;
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 1px 5px 10px 3px #000;
    /* Firefox 3.5 - 3.6 */
    box-shadow: 1px 5px 10px 3px #000;
    /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    font-size: x-large;
    padding: 2px;
    border-radius: 5px;
}

.premium_featured_image_upload .fileContainer {
    box-shadow: none;
    padding: 0;
    margin: auto;
}

.premium_featured_image_upload .fileContainer .chooseFileButton, .premium_featured_image_upload .fileContainer .chooseFileButton:focus, label.text {
    margin: auto;
    padding: 18px 28px;
    font-size: large;
    font-weight: bold;
    border: 2px solid;
    border-radius: 30px;
    -webkit-box-shadow: 1px 5px 10px 3px #000;
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 1px 5px 10px 3px #000;
    /* Firefox 3.5 - 3.6 */
    box-shadow: 1px 5px 10px 3px #000;
    /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.button_space {
    margin-left: 2px;
}

.thumbnail.right-caption>img {
    display: inline-block;
    margin-right: 10px;
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.thumbnail.right-caption>.caption {
    padding: 5px;
    display: inline-block;
    vertical-align: middle;
    width: 80%;
}

.thumbnail_star {
    display: inline-flex;
}

.review_footer span {
    padding-right: 15px;
}

.comment_desc {
    margin-top: 5px;
}

.reverse_row {
    flex-direction: row-reverse;
}

.ant-calendar-picker {
    height: auto !important;
}

.file_job {
    position: relative;
    overflow: hidden;
    font-size: 1.125rem;
    font-weight: 500;
    cursor: pointer;
    z-index: 9999;
}

.file_job input {
    opacity: 0;
    left: 0;
    position: absolute;
}
.jobDateInput {
    display: none;
}

#lightboxBackdrop button:first-child {
    font-weight: bold;
    font-size: x-large;
    padding-left: 0;
}

#lightboxBackdrop button:focus {
    outline: 1px dotted;
}

#lightboxBackdrop figure img { 
    width: auto;
    height: auto;
}

#lightboxBackdrop figcaption {
    font-weight: bold;
    text-transform: initial;
}

.notification-container-top-center {
    top: 35% !important;
}

.notification-close span, .notification-message, .notification-title {
    text-shadow: 1px 1px 2px #000;
}

.notification-item {
    border: 8px solid;
}

/*PopUp Model*/

.modal {
    font-size: 0.75rem;
    position: relative;
    display: block;
    overflow: visible;
    /* to make large number of selected address visible */
}

.modal>.header {
    width: 100%;
    border-bottom: 1px solid;
    font-size: 1.125rem;
    text-align: center;
    border-color: #cccccc;
    padding: 5px;
}

.modal>.content {
    width: 100%;
    padding: 10px 5px;
}

.modal>.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 5px;
    line-height: 8px;
    right: 0;
    top: 0;
    font-size: 0.9375rem;
    border-radius: 18px;
    border: 1px solid;
    background: #ffffff;
    border-color: #cccccc;
}

.modal>.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.icon_button {
    display: inline-block;
    padding: 2px;
    margin: auto;
}

/* Package Selection*/

.listing_rating .dv-star-rating {
    vertical-align: top;
}

/** added by Sekhar for lookup and home page purpose **/

.listing_info_box {
    border: 1px solid;
    height: auto;
    /* margin: auto .25rem .8rem; */
    margin: auto;
    width: 100%;
}

.listing_info_box h4 {
    font-weight: 600;
    padding-top: 10px;
    margin-bottom: 2px;
}

.listing_info_box .listing_info_box_desc {
    overflow: hidden;
    height: 95px;
    /*margin-bottom: 20px;*/
    font-weight: 500;
}

.listing_info_box .listing_info_box_desc p.event_p {
    margin: 7px auto;
}

.listing_info_box .listing_info_box_desc_newsArt {
    /*overflow: hidden;*/
    height: auto;
    font-weight: 500;
    font-size: 0.9rem;
}

.listing_info_box .info_addbox {
    margin-top: 6px;
    border-left: 1px solid;
    padding: 7px;
    overflow: hidden;
    min-height: 135px;
    max-height: 145px;
}

.listing_info_box .person_contactbox {
    padding: 10px;
}

.row.rate_phone_person_box {
    margin-top: 13px;
}

.listing_info_box .listing_rating, .listing_info_box .person_number {
    position: relative;
    border-radius: 7px;
    padding: 1px 5px;
    bottom: auto;
    top: 4px;
    width: fit-content;
    font-size: medium;
}

.listing_info_box .person_number {
    padding: 3px 5px;
}

.listing_info_box .inner_row {
    border-bottom: 1px solid;
}

.listing_info_box .user_name {
    position: relative;
    border-radius: 7px;
    bottom: auto;
    top: 4px;
    width: fit-content;
    font-size: medium;
    padding: 2px 5px;
}

.listing_info_box .fa-envelope, .listing_info_box .fa-map-marker, .listing_info_box .fa-calendar, .listing_info_box .fa-clock-o, .listing_info_box .fa-user, .listing_info_box .fa-globe, .listing_info_box .fa-phone-square {
    font-size: 1.375rem;
    vertical-align: middle;
}

.listing_info_box .info_addbox .addbox_icon {
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: initial;
    width: auto;
}

.listing_business_box {
    margin-bottom: 20px;
}

.listing_business_box .single_business_box, .contact_info_box, .working_hours_box, .email_website {
    border: 1px solid;
}

.news_listings .listing_info_box {
    margin: auto .25rem .8rem;
}

/** end of dded by Sekhar for lookup and home page purpose **/

/*home page listings*/

.items_list .listing_card {
    position: relative;
    padding: 0;
    overflow: hidden;
    /* border: 1px solid; */
    width: 100%;
    height: 400px;
    width: auto;
    cursor: pointer;
}

.items_list .clip_card, .clip_card {
    position: relative;
    /* padding: 0; */
    overflow: hidden;
    /* width: 230px;
    height: 375px; */
    border: 1px solid;
    cursor: pointer;
    border-radius: 15px;
    border: 1px solid #ccc;
    text-align: center;
    margin: auto;
    height: 100%;
    /* width: 100%; */
   /* width: fit-content; */
}

.items_list .clip_card img, .clip_card img {
    max-height: auto;
    /* aspect-ratio: 9/16; */
    width: 100%;
    object-fit: fill;
}

.card_details {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px;
    color: white;
}

.display_inline_block {
    display: inline-block !important;
    margin-left: 3px;
}

.listing_avarage_rating {
    border-radius: 2px;
    padding-left: 2px;
    padding-right: 2px;
    line-height: initial;
}

.listing_review_count {
    font-size: 0.65rem;
    vertical-align: middle;
}

.listing_address {
    font-size: 0.9rem;
    max-height: 175px;
    display: table;
    height: 160px;
}

.listing_address .inner_box {
    display: inline-table;
    vertical-align: middle;
    line-height: 17px;
    margin-top: auto;
}

.review_box {
    margin-bottom: 15px;
    border: solid 1px #333;
    border-radius: 10px;
    box-shadow: 0 0 1px 0 #333;
    padding: 10px;
}

.listing_details {
    padding: 5px;
    min-height: 15px;
}

.listing_title {
    /* max-height: 2px; */
    /*overflow: hidden;*/
    font-size: 1.25rem;
    font-weight: 600;
    /* margin-bottom: 20px; */
}

.mob_listing_title {
    max-height: 2px;
    /*overflow: hidden;*/
    font-size: 0.9rem;
    margin-bottom: 20px;
}

.listing_thumb_image img {
    object-fit: cover;
    width: 233px;
    min-height: 233px;
    max-height: 233px;
}

.video_card_desc {
    margin-left: 180px;
}

.newsArt_thumb_image img {
    object-fit: cover;
    height: auto;
    min-width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.newsArt_thumb_image {
    border-right: 1px solid;
    height: auto;
    min-width: auto;
    width: auto;
}

.listing_thumb_image {
    border-bottom: 1px solid;
    width: 233px;
    min-height: 233px;
    max-height: 233px;
}

/* .listings {
    max-width: 1325px;
} */

.items_list .listing_description, .lookup-thumb-description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    /*margin: 5px;*/
    padding: 5px;
    text-align: center;
    /* height: 50px; */
    vertical-align: middle;
    /* transition effect. not necessary */
    transition: opacity 1s, visibility 1s;
}

.items_list .clip_description, .clip_description {
    position: absolute;
    /* top: 80%; */
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    /*margin: 5px;*/
    text-align: bottom;
    height: 100px;
    vertical-align: middle;
    /* transition effect. not necessary */
    transition: opacity 1s, visibility 1s;
}

.listing_description .view_button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.listing_description .view_button button.btn-link, .advanced_search_labels .btn-link {
    padding: 5px;
    font-size: .8rem;
    position: relative;
    /*left: 50%;*/
    /*transform: translate(-10%, 0);*/
    margin: auto;
    text-decoration: none;
    vertical-align: baseline;
}

.listings row .listing_preview {
    margin-top: 10px;
}

.listing_description .customer_header, .lookup-thumb-description .customer_header {
    font-weight: bold;
    font-size: .75rem;
    margin: auto;
    /*padding-top: 5px;*/
    padding-left: 5px;
    padding-right: 5px;
}

.items_list .listing_card:hover .listing_description,.items_list .clip_card:hover .clip_description, .clip_card:hover .clip_description, .items_list .listing_card_video:hover .listing_description, .listing_info_box:hover .lookup-thumb-description {
    visibility: visible;
    opacity: 1;
}

/**
* overrridden properties of lookup page card hover
*/

.listing_info_box:hover .lookup-thumb-description {
    color: #fff;
    font-size: .75rem;
}

.listing_info_box:hover .lookup-thumb-description .customer_header {
    color: #FF9933;
}

.listing_rating {
    position: absolute;
    bottom: -1px;
}

.popup_listing_title {
    color: #FF9933;
}

.spinner div {
    position: relative;
    display: block;
    text-align: center;
    margin: 15% auto;
}

.spinner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 768px;
    height: 100vh;
    width: 100%;
    z-index: 9999;
}

.newsArt_link {
    /*cursor: pointer;*/
    width: fit-content;
    font-size: 1.20rem;
}

.curser_hand {
    cursor: pointer;
    transition: all 0.5s ease;
}

.curser_hand:hover {
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.curser_hand h3, .curser_hand h4, .curser_hand h5, .curser_hand h6 {
    font-weight: bold;
    transition: all 0.5s ease;
}

.newsArt_Desc {
    padding: 7px !important;
    margin-left: -16px;
}

.listing_description .premium_desc {
    max-height: 142px;
    overflow: hidden;
}

.lookup-thumb-description {
    width: 210px;
}

.listing_description p, .lookup-thumb-description p {
    height: auto;
    overflow: hidden;
    padding: 4px;
    margin: auto;
}

.clip_description .description {
    height: auto;
    overflow: hidden;
    padding: 10px;
}

/*.listing_description p.review_desc {*/

/*    max-height: 85px;*/

/*}*/

.listing_description p.description, .lookup-thumb-description p.description {
    height: auto;
    word-spacing: 1.02px;
    letter-spacing: 0.5px;
    font-size: 0.85rem;
}

.lookup-thumb-description p.description {
    height: auto;
    word-spacing: 1.02px;
    letter-spacing: 0.5px;
    font-size: 0.80rem;
}

.lookup-thumb-description p.review_desc {
    max-height: 75px;
}

.thumbnail_image {
    height: 30px;
    margin-top: -3px;
}

.comment {
    margin: 20px;
    display: flex;
    padding-left: 50px;
}

.date_margin {
    margin-left: 13px;
}

.reply {
    padding: 5px;
    width: 100%;
}

.review_user span {
    font-size: small;
}

.current_user_review {
    margin-bottom: 10px;
}

.review_user {
    font-weight: bold;
}

.comment-header {
    margin: auto auto auto 0;
    font-size: small;
}

.comment-header span {
    font-size: smaller;
    font-weight: lighter;
}

.comment-footer .btn-link, .comment-box .btn-link {
    padding: 0;
    font-size: inherit;
    text-decoration: none;
}

.comment-box .btn-link i.fa {
    font-size: large;
}

.comment-footer .btn-link:active, .comment-box .btn-link:active, .comment-footer .btn-link:hover, .comment-box .btn-link:hover {
    font-weight: bold;
}

.comment-footer, .comment-body {
    display: inline-block;
}

.taged_comment {
    margin-left: 64px;
    padding: 3px 6px 25px;
    margin-bottom: -40px;
    background-image: linear-gradient(#e5e5f2, #fffaf5);
    border-radius: 10px 10px 0 0;
    width: 85%;
    box-shadow: inset 0 5px 5px -6px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.review_button {
    margin-top: 5px;
}

.review_text {
    margin-top: 5px;
    margin-bottom: 5px;
}

.small_thumbnail h6 {
    font-size: .8rem;
    font-weight: 500;
}

.small_thumbnail.right-caption>img {
    height: 80px;
    width: 80px;
    margin-right: 5px;
}

.ReactGridGallery_tile-viewport {
    width: 145px !important;
}

.social_network {
    display: inline-block;
}

.cursor_pointer {
    cursor: pointer;
}

.article_header, .review_user {
    margin: 28px;
}

.article_mobHeader {
    margin: 2px;
}

.single_listing_rating {
    display: inline;
    margin-right: 15px;
    margin-bottom: auto;
    margin-top: .5rem;
}

.single_listing_rating .dv-star-rating {
    vertical-align: text-bottom;
    margin-right: 5px;
}

.single_listing_rating .dv-star-rating-star {
    margin-bottom: auto;
}

.single_listing_rating .listing_review_count {
    font-size: .75rem;
    vertical-align: text-top;
}

.basic_time_div {
    display: flex;
}

.basic_time_delete {
    padding: 5px 10px;
    line-height: normal;
}

.rdw-image-modal, .rdw-embedded-modal {
    left: -165px !important;
    z-index: 10 !important;
}

.rdw-link-modal {
    height: auto !important;
    left: -60px !important;
}

.rdw-embedded-modal {
    height: auto !important;
    left: -130px !important;
}

.rdw-link-decorator-icon {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.rdw-editor-main {
    height: auto !important;
    font-size: 18px;
}

.rdw-editor-main video {
    display: none !important;
}

.rdw-image-imagewrapper img {
    width: 100% !important;
}

.listing_preview .rdw-editor-main iframe, .listing_preview .rdw-editor-main img, .listing_preview .rdw-editor-main figure, .rdw-editor-main video {
    width: 100% !important;
}

div.focus>div>div {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.btn:disabled {
    cursor: not-allowed;
}

.ant-calendar-header a.ant-calendar-next-year-btn, .ant-calendar-header a.ant-calendar-prev-year-btn {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
}

.ant-calendar-header a.ant-calendar-next-year-btn::before, .ant-calendar-header a.ant-calendar-prev-year-btn::before, .ant-calendar-header a.ant-calendar-next-year-btn::after, .ant-calendar-header a.ant-calendar-prev-year-btn::after {
    border: none;
}

/* Styling h1 and links
  ––––––––––––––––––––––––––––––––– */

/*San Addpost new&article fields css */

.newsart_main .insert_tab .nav-item {
    padding-right: 10px;
}

/*San Payment Page Css*/

.payment_container .set_heading {
    padding-right: 25px;
}

.view-count-input-box {
    width: 200px;
}

/* .payment_container p{
  margin-bottom: 2px;
} */

.payment_container .sponsored_row, .payment_container .paynow_button {
    margin-top: 15px;
}

.payment_container .set_count {
    padding: 10px 0px;
}

.payment_container .switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 22px;
}

.payment_container .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.payment_container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
}

.payment_container .button_on {
    position: absolute;
    top: 4px;
    left: 7px;
    right: 0;
    bottom: 0;
    font-size: .625rem;
}

.payment_container .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 1px;
    -webkit-transition: .4s;
    transition: .4s;
}

.payment_container input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

.payment_container input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.payment_container .slider.round {
    border-radius: 34px;
}

.payment_container .slider.round:before {
    border-radius: 50%;
}

.payment_container .paynow_padding {
    padding-right: 10px;
}

.payment_container .order_summary {
    padding: 15px;
    border-top: 5px solid;
    border-bottom: 5px solid;
}

.payment_container .order_summary p {
    padding-bottom: 10px
}

.container_radio {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-bottom: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
  }
  
  /* Hide the browser's default radio button */
  .container_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ccc;
    border-radius: 50%;
  }

  .checkmark_regis {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ccc;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container_radio:hover input ~ .checkmark, .container_radio:hover input ~ .checkmark_regis{
    background-color: #FF9933;
  }
  
  /* When the radio button is checked, add a blue background */
  .container_radio input:checked ~ .checkmark, .container_radio input:checked ~ .checkmark_regis {
    background-color: #138808;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after, .checkmark_regis:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container_radio input:checked ~ .checkmark:after, .container_radio input:checked ~ .checkmark_regis:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container_radio .checkmark:after, .container_radio .checkmark_regis:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #FF9933;
  }

  .copyBankbutton {
    color: #FF9933;
    -webkit-text-stroke: 1px #138808;
    border: none;
  }

.no_more {
    padding: 0px 5px 10px;
    text-align: center;
    font-weight: 500;
    text-transform: capitalize;
}

.form-group {
    margin-bottom: .5rem;
}

.contact_info_box, .working_hours_box, .email_website, .image_gallery_box {
    padding: 0px 10px 10px 10px !important;
}

.display_grid {
    display: grid;
}

.mobile_label {
    display: none;
}

.manage_mobile {
    display: none;
    margin-bottom: .15rem;
    padding-left: .25rem;
    color: black;
}

.working_hours_inner {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.list_Summary label {
    margin-bottom: 0;
    font-weight: 600;
}

.public-DraftStyleDefault-block {
    margin: .5em auto 0 auto !important;
}

.reg_popup-content .modal>.content {
    overflow-y: auto;
    height: 70vh;
    overflow-x: hidden;
    margin: auto;
}

.report_popup-content .modal>.content {
    overflow-y: auto;
    height: 70vh;
    overflow-x: hidden;
    margin: auto;
}

h1 {
    display: block;
    font-size: 2em;
    font-weight: bold;
    margin: .67em 0;
}

h2 {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    margin: .83em 0;
}

h3 {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
    margin: 1em 0;
}

h4 {
    display: block;
    font-size: 1em;
    font-weight: bold;
    margin: 1.33em 0;
}

h5 {
    display: block;
    font-size: .83em;
    font-weight: bold;
    margin: 1.67em 0;
}

h6 {
    display: block;
    font-size: .67em;
    font-weight: bold;
    margin: 2.33em 0;
}

.header_payment {
    background-color: #ffa500;
    color: white;
    display: flex;
    align-items: center;
    height: 80px;
  }
  
  .header_payment .logo {
    height: 60px;
    padding-right: 30px;
    padding-left: 75px;
  }
  
  .header_payment .header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header_payment h1 {
    margin: 0;
    font-size: 24px;
    color: white;
  }
  
  .header_payment h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    color: white;
  }

  .payment_popup_content {
    font-size: 14px;
    color: #000080;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .footer-note {
    background-color: #138808;
    color: white;
    padding: 5px 5px 5px 5px;
    font-size: 14px;
    text-align: center;
  }

/*==================================================
 * Effect 7
 * ===============================================*/

.effect7 {
    position: relative;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.effect7:before, .effect7:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    left: 10px;
    right: 10px;
    -moz-border-radius: 100px / 10px;
    border-radius: 100px / 10px;
}

.effect7:after {
    right: 10px;
    left: auto;
    -webkit-transform: skew(8deg) rotate(3deg);
    -moz-transform: skew(8deg) rotate(3deg);
    -ms-transform: skew(8deg) rotate(3deg);
    -o-transform: skew(8deg) rotate(3deg);
    transform: skew(8deg) rotate(3deg);
}

/**
 * Tooltip Styles
 */

/* Add this attribute to the element that needs a tooltip */

[data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
}

/* Hide the tooltip content by default */

[data-tooltip]:before, [data-tooltip]:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: opacity(0%);
    opacity: 0;
    pointer-events: none;
}

/* Position tooltip above the element */

[data-tooltip]:before {
    position: absolute;
    /*bottom: 150%;*/
    left: 50%;
    /*margin-bottom: 5px;*/
    margin-left: -80px;
    padding: 7px;
    width: 160px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    content: attr(data-tooltip);
    text-align: center;
    font-size: .875rem;
    line-height: 1.2;
    margin-top: 20px;
}

/* Triangle hack to make tooltip look like a speech bubble */

[data-tooltip]:after {
    position: absolute;
    /*bottom: 150%;*/
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-bottom: 5px solid #000;
    border-bottom: 5px solid hsla(0, 0%, 20%, 0.9);
    border-right: 5px solid;
    border-left: 5px solid;
    content: " ";
    font-size: 0;
    line-height: 0;
    margin-top: 15px;
}

/* Show tooltip content on hover */

[data-tooltip]:hover:before, [data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: opacity(100%);
    opacity: 1;
}

/* Show tooltip content on hover */

[data-tooltip]:hover:before, [data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: opacity(100%);
    opacity: 1;
    white-space: normal;
}

/*Theme*/

body, .payment_container .order_summary {
    background: #e7f3e6 !important;
}

body, .post_section .image_label {
    color: #333333 !important;
}

.listing_info_box .listing_info_box_desc_newsArt, .listing_review_count, .listing_address, .single_listing_rating .listing_review_count, .search_strip .multi_select [class$="-group"], .search_strip .multi_select [class$="-group"]:active, .color_indicators .black, .nav-tabs .nav-link:focus .list_count, .nav-tabs .nav-link:hover .list_count, .nav-tabs .nav-item.show .nav-link .list_count, .nav-tabs .nav-link.active .list_count, .comment-header, .comment-box .btn-link {
    color: #333333;
}

.resource_details .resource_title, .resource_details .resource_rating, .resource_details .resource_social .resource_social_share, .gallery_images .opening_hours_label, .gallery_images .opening_hours .day_label, .gallery_images .address_label, .items_list .card_icon, .items_list .card_title, .items_list .address_icon, .search_strip .multi_select .serach_more a, .contact_rply p, .login_description, .listing_info_box .fa-phone-square, .listing_info_box .fa-clock-o, .curser_hand:hover, .curser_hand:hover h3, .curser_hand:hover h4, .curser_hand:hover h5, .curser_hand:hover h6, .starrating>input:checked~label, .starrating>input:hover~label, .payment_container .set_heading, .color_indicators .green, .preview_header code, .date {
    color: #138808;
}

.bottom_strip, .search_strip, .premium_featured_image_upload .fileContainer .chooseFileButton:hover, label.text:hover, .payment_container input:checked+.slider {
    background: #138808;
}

.topbar-nav, .resource_details, .gallery_images .opening_hours_label .calender_icon, .items_list .card_icon, .payment_container .order_summary {
    border-color: #138808;
}

.article_header, .article_mobHeader, .no_data, .add_button button, .add_button button:hover, .add_button button:active, .file_job, .listing_info_box .fa-envelope, .listing_title, .mob_listing_title, .listing_description .customer_header, .newsArt_link, .review_user span, .comment-header span, .comment-footer .btn-link, .letter, .advanced_search_labels .btn-link, .color_indicators .red, .date_title {
    color: #FF9933;
}

.btn-warning, .btn-warning:hover, .login-button .toggle-button, .premium_image_upload .fileContainer .chooseFileButton, .premium_featured_image_upload .fileContainer .chooseFileButton, .premium_featured_image_upload .fileContainer .chooseFileButton:focus, label.text, .listing_avarage_rating {
    background: #FF9933;
}

.btn-warning, .btn-warning:hover {
    border-color: #FF9933;
}

.avatar-dropdown-btn, .dropdown-btn, .list_Summary label, .contact_maincontent label, .listing_info_box .fa-calendar, .middle_strip .right_side_btn li a, .color_indicators .blue, .listing_info_box .fa-user, .listing_info_box .fa-globe {
    color: #000080;
}

.btn-info, .btn-info:hover, .middle_strip .LeaderBoard_Add, .Half_Page_Ad {
    background: #000080;
}

.btn-info, .btn-info:hover {
    border-color: #000080;
}

.btn:disabled, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background: #bbbbbb;
}

.btn:disabled, .nav-tabs .nav-link {
    color: #222222;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color: #222222;
}

.btn-warning, .btn-warning:hover, .btn-info, .btn-info:hover, .listing_avarage_rating, .items_list .listing_description, .payment_container .button_on, [data-tooltip]:before, .listing_description .rdw-storybook-roots, .listing_description .rdw-storybook-root, .listing_description p, .login-button .toggle-button, .middle_strip .LeaderBoard_Add h5, .contact_subbtn, .contact_subbtn:hover, .items_list .img_description, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .contact_link a, .contact_link a:hover, .contact_maincontent .btn_sendmail, .contact_maincontent .btn_sendmail .btn-warning:hover, .advanced_search .search_button, .advanced_search .search_button:hover, .btn_gray, .btn_gray:active, .btn_gray:hover, .gallery_over_lay, .premium_featured_image_upload .fileContainer .chooseFileButton:hover, label.text:hover, #lightboxBackdrop button:first-child, .premium_featured_image_upload .fileContainer .chooseFileButton, .premium_featured_image_upload .fileContainer .chooseFileButton:focus, label.text, .animated_back_button button, .animated_back_button button:hover {
    color: #ffffff;
}

.contact_info_box, .working_hours_box, .email_website, .items_list .card_icon, .nav-tabs .nav-link:focus .list_count, .nav-tabs .nav-link:hover .list_count, .nav-tabs .nav-item.show .nav-link .list_count, .nav-tabs .nav-link.active .list_count, .contact_rply, .item .close, .item .close i, .listing_details, .newsArt_thumb_image, .listing_thumb_image, .listing_rating, .payment_container .slider:before, .listing_info_box {
    background: #ffffff;
}

.contact_info_box, .working_hours_box, .email_website, .btn:disabled, .nav-tabs .nav-link, .middle_strip .right_side_btn li, .listing_info_box, .BillBoard_Ad img, .Large_Rectangle_Ad img, .bottom_strip .dropdown:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .listing_business_box .single_business_box, .items_list .listing_card, .items_list .listing_card_video, .search_strip .multi_select button~div:first-of-type, .wrapper-class, .listing_info_box .info_addbox, .newsArt_thumb_image, .listing_thumb_image, .listing_info_box .inner_row {
    border-color: #cccccc;
}

.payment_container .slider {
    background: #cccccc;
}

.content, .header, .admin-content {
    background: #fffaf5;
}

.listing_info_box .listing_rating, .listing_info_box .person_number, .resource_details, .resource_details input[type="text"], .gallery_images .address_label, .gallery_images .opening_hours_label, .gallery_images .opening_hours, .nav-tabs .nav-link, .right_side_bar {
    background: #e5e5f2;
}

.gallery_images .opening_hours_label, .gallery_images .address_label, .notification-item {
    border-color: #e5e5f2;
}

.map_marker, .item .close i, .error, .listing_info_box .fa-map-marker {
    color: #FF0000;
}

.items_list .img_description {
    background: rgba(29, 106, 154, 0.72);
}

.toolbar-class {
    background-color: #dddddd !important;
    padding-right: 50px;
}

.gallery_over_lay {
    background: rgba(0, 0, 0, 0.7) !important;
}

.items_list .clip_description, .clip_description, .items_list .listing_description, .lookup-thumb-description {
    background: rgba(51, 51, 51, 0.95);
}

.spinner {
    background: rgba(0, 0, 0, 0.6);
}

[data-tooltip]:before {
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
}

[data-tooltip]:after {
    border-bottom: 5px solid #000;
    border-bottom: 5px solid hsla(0, 0%, 20%, 0.9);
}

.middle_strip .right_side_btn li:last-child, .items_list .card_footer, .nav-tabs, input.no_padding, input.no_padding:focus, .premium_time_picker .ant-input, #lightboxBackdrop button:first-child {
    border: none;
}

.search_strip [class$="-option"], .search_strip [class$="-option"]:active, .search_strip .multi_select [class$="-group"]+div>[class$="-option"], .search_strip .multi_select [class$="-group"]+div>[class$="-option"]:active, .search_strip .multi_select [class$="-group"], .search_strip .multi_select [class$="-group"]:active, .premium_featured_image_upload .fileContainer, .Registration button.btn-link, .Registration button.btn-link:hover {
    background: none;
}

#lightboxBackdrop button:first-child {
    background: transparent;
}

[data-tooltip]:after {
    border-color: transparent;
}

.gallery_images .address_label .address_social_icons, .preview_video, .payment_container .paynow_button, .payment_container .order_summary .list_Summary, .table_data_right {
    text-align: right;
}

.custom-container h2, .right_side_btn, .nav-tabs .nav-item, .login_description, .error, .load-wrapp, table, th, td, .popup_title {
    text-align: center;
}

.view-table, .table_data_left {
    text-align: left;
    line-height: initial;
}

.openhours .table, .items_list, .listing_info_box .listing_rating label {
    margin: auto;
}

.show, .image_hover:hover .item .close, .social_container {
    display: block;
}

.bordered-table {
    width: 100%;
    border-collapse: collapse;
}

.bordered-table td, 
.bordered-table th {
    border: 1px solid black; /* You can change the color */
    padding: 8px; /* Optional: adjust cell padding */
}

.bordered-table .table-row {
    height: 50px; /* Optional: adjust row height */
}


/*Avatar*/

.avatar {
    z-index: 3;
}

/* profile dropdown for mibile view */
.dropdown {
    position: relative;
    display: block;
    top: -25px;
    left: 15px;
  }
  
/* post manage table */

table {
    width: 100%;
}

thead {
    color: #138808;
}

tbody, thead {
    color: #333;
}

th {
    padding: 1px 2px;
    background-color: #e5f1e4;
    font-weight: 500;
}

td {
    padding: 2px;
    /* font-weight: 300; */
    height: 35px;
}

.table-row {
    border-bottom: 1px solid #0000001c;
}

.featured, .sponsored {
    width: 200px;
}

.lisitng-title {
    width: 150px;
}

.ad-name {
    width: 32rem;
}

.count {
    width: 100px;
}

.date {
    width: 7rem;
}

.ballance {
    width: 100px;
}

.actions {
    padding: 1px;
    cursor: pointer;
}

.delete {
    color: red;
}

.action-list {
    position: absolute;
    background-color: rgb(231, 207, 245);
    color: darkblue;
    width: 75px;
    z-index: 3;
    box-shadow: 0px 1px 3px 0px #000000e0;
    text-align: center;
    right: 40px;
    border-radius: 5%;
    padding: 3px;
    margin-top: 2px;
    animation-name: fadeIn;
    animation-duration: 0.5s;
}

.action-list::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    right: 21px;
    border-width: 0 6px 6px 6px;
    border-style: solid;
    border-color: rgb(231, 207, 245) transparent;
    /*background-color: rgb(231, 207, 245);*/
}

.edit-opt {
    font-size: 10px;
    line-height: .5rem;
    padding: 3px;
}

.dropdown-btn, .avatar-dropdown-btn {
    width: 100%;
    text-align: left;
}

.left_side_bar {
    display: contents;
}

.admin-tab {
    float: left;
    padding-top: 2rem;
    /* position: fixed; */
    border-right: 1px solid #000080;
}

.admin-panel {
    display: flex;
    min-height: 100vh;
}

.admin-content {
    float: left;
    padding-top: .5rem;
}

.admin-tab-ul {
    color: #333;
    position: fixed;
}

.admin-tab-scroll-container {
    max-height: 530px;
    overflow-y: auto;
    padding-right: 20px;
}

.admin-tab-scroll-container::-webkit-scrollbar {
    width: 10px;
}

.admin-tab-li {
    padding: .4rem;
}

.tab-link {
    color: #333;
    font-weight: 600;
}

.activeAdminTab {
    color: #138808;
}

.dashboard {
    padding-left: 30%;
    padding-top: 5rem;
    width: 40rem;
}

.search_box__input, .status_selector__input {
    display: block;
    width: 100%;
    border: 1px solid green;
    padding-left: 0.3rem;
    cursor: default;
}

.search_box__value::after, .status_selector__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

.status_selector__value::after {
    top: calc(50% - 12px);
}

.search_box *, .search_box *::after, .search_box *::before {
    box-sizing: inherit;
}

.search_box__option, .status_selector__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.search_box__option.is-selected, .status_selector__option.is-selected {
    background: rgb(47, 204, 139);
    color: #fff;
}

.search_box__select, .status_selector__select {
    top: 30px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.search_box__options, .status_selector__options {
    list-style: none;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    /* padding-inline-start: 40px; */
}

.search_box__select, .date_picker, .status_selector__select {
    position: absolute;
    z-index: 2;
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

.search-bar {
    margin-top: 15px;
}

.admin-content-head {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 15px;
}

.page-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 415px;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: #138808;
    border-color: #138808;
    color: #fff;
}

.pagination>li>a {
    border: 1px solid #138808;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
    background-color: #138808;
    border-color: #138808;
    outline: none;
}

.pagination>li>a, .pagination>li>span {
    color: #138808
}

.pagination>li:first-child>a, .pagination>li:first-child>span, .pagination>li:last-child>a, .pagination>li:last-child>span {
    border-radius: unset
}

.reload_icon {
    padding-left: 5px;
    padding-right: 10px;
    padding-top: 1px;
    cursor: pointer;
}

.suggestion_box {
    position: absolute;
    z-index: 1;
    /* display: none; */
}

.update_popup_box {
    width: 500px;
    height: 400px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.remark {
    padding-top: 2rem;
    height: 80%;
    width: 80%;
    margin-left: 10%;
}

.remark_input {
    height: 90%;
    width: 100%;
}

.remarkLabel {
    margin-bottom: 0;
}

.remark-overview {
    text-align: justify;
}

.confirm_popup, .confirm_message {
    padding: 10px;
}

#lightboxBackdrop figcaption {
    overflow-wrap: break-word;
}

.gallery-control {
    height: 80%;
    margin: 5px;
    padding: .2rem;
}

.change-caption-btn {
    font-size: .8rem;
}

.change-caption-inpt {
    border-radius: .25rem;
}

.active_indicator {
    color: green;
}

.blocked_indicator {
    color: red;
}

.incomplete_indicator {
    color: yellow;
}

.paused_indicator {
    color: blue;
}

.expired_indicator {
    color: #000;
}

.renew_indicator {
    background-color: darkgray;
    color: black;
}

.delete_indicator {
    color: white;
    background-color: #000;
}

.renew_circle {
    font-weight: 700;
    border-radius: 50%;
    font-size: 10px;
    padding-left: 3px;
    padding-right: 4px;
  }

.adblocker-notice {
    z-index: 999;
    position: fixed;
    background-color: #fff;
    border: solid 1px #FF9933;
    border-top: 0px;
    width: 300px;
    height: 200px;
    padding: 20px;
    text-align: center;
}

/**
* set text to no wrap if not enough space text will overflow
*
* payment page set view count input error message
*/

.no-wrap-text {
    float: left;
    white-space: nowrap;
}

/**
* add left margin to notice message on action popup
*/

.enable_notice {
    margin-left: 10px;
}

/* advanced listing content banner*/

.fileSizeError {
    background-color: white;
    display: flex;
    margin-bottom: 10px;
}

.deemBackground {
    background: rgba(51, 51, 51, 0.95);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

.avatar-shape {
    border-radius: 50%;
}

.avatar-shape-reply {
    margin-top: 5px;
    width: 6%;
    height: min-content;
}

.media-btn {
    margin-left: 5px;
}

.working_hour_time_picker {
    z-index: 1 !important;
}

/*
  Tool tip for copy ref link to clipboard 
*/

.tool_tip {
    position: relative;
    display: inline-block;
}

.tool_tip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #333333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 50%;
    margin-left: -75px;
    transition: opacity 0.3s;
}

.tool_tip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tool_tip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/***********************************************
               View-Report Page
************************************************/

.view-date {
    margin: 35px 224px 0 40px;
}

.admin-view-head {
    padding-top: 25px;
}

.table_head {
    background-color: #e5f1e4;
}

.view-th-head {
    color: #FF9933;
    padding: 10px;
}

.view-th {
    padding-left: 10px;
    text-align: left;
}

/***End of the View-Report Page***/

/***********************************************
              View-Listing Performance
************************************************/

.ViewPerformance, .views {
    display: flex;
}

.performance-nav {
    background-color: #e5f1e4;
    font-weight: bold;
}

.extra_height {
    height: 53px;
}

.white_readonly {
    background-color: #fff !important;
}

.date_range_picker i {
    position: absolute;
    right: 5px;
    top: 3px;
}

.date_range_picker_input input {
    cursor: default;
}

.cal_icon {
    padding: 10px;
    min-width: 40px;
}

.animated_back_button {
    text-align: -webkit-right;
    text-align: -moz-right;
    text-align: -o-right;
}

.animated_back_button button {
    width: 18%;
    border-radius: 50%;
    cursor: default;
    overflow: hidden;
    transition: 300ms linear
}

.animated_back_button button:hover {
    width: 100%;
    border-radius: .25rem;
}

.user_panel_clear_filter {
    padding-top: 8px;
    padding-left: 10px;
}

.viewed_mark {
    position: absolute;
    right: 5px;
    bottom: 8px;
    color: #138808;
    background: white;
    border-radius: 50%;
    border: solid 1px #fff;
}
.ad_mark {
    position: absolute;
    font-size: 10px;
    right: 1px;
    top: 1px;
    color: black;
    padding-left: 2px;
    padding-right: 2px;
    background: grey;
}

/*--------------------------------------------------------------
                      Search Section
--------------------------------------------------------------*/

/***#topbar
* This class defines the style of the container of search component
* This class include classes:-
* - #.bottom_strip defines the position of the inner multi select search box
* - #.bottom_strip .search_button
* - #.bottom_strip .form-inline .form-control
* - #
***/

.topbar {
    position: sticky;
    width: 100%;
    left: 0;
    z-index: 10;
}

.bottom_strip .form-inline .form-control {
    width: 100%;
}

.avatar-dropdown-btn {
    font-size: 0.875rem;
}

.nav-tabs .search_tab ul li .nav-link {
    background-color: #138808;
    /* width: 145px; */
    border-color: #138808;
    color: #fff;
    margin-top: -4px;
}

.search_tab ul li .nav-link:hover, .search_tab ul li .nav-link.active {
    background-color: #FF9933;
}

.search_tab .nav-link {
    height: 38px;
}

/*** #.hide-search-bar
* This class defines the search bar hides after scrolling the window
***/

.scrolled-down {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
}

.scrolled-up {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

#nav-toggle {
    position: absolute;
    left: 8px;
    top: 12px;
    cursor: pointer;
    padding: 4px 12px 8px 0px;
}

#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 25px;
    background: #ffffff;
    position: absolute;
    display: block;
    content: "";
    transition: all 300ms ease-in-out;
}

#nav-toggle span:before {
    top: -10px;
}

#nav-toggle span:after {
    bottom: -10px;
}

#nav-toggle.active span {
    background-color: transparent;
}

#nav-toggle.active span:before, #nav-toggle.active span:after {
    top: 0;
}

#nav-toggle.active span:before {
    transform: rotate(45deg);
}

#nav-toggle.active span:after {
    transform: rotate(-45deg);
}

/*** End of Search Section ***/

.redirect_link {
    color: #FF9933;
}

.rct-node-collapsed ol {
    animation-name: slideUp;
    -webkit-animation-name: slideUp;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible;
}

.rct-node-expanded ol {
    animation-name: slideDown;
    -webkit-animation-name: slideDown;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible;
}

@keyframes slideDown {
    0% {
        transform: translateY(-25%);
    }
    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes slideDown {
    0% {
        -webkit-transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0%);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-25%);
    }
}

@-webkit-keyframes slideUp {
    0% {
        -webkit-transform: translateY(0%);
    }
    100% {
        -webkit-transform: translateY(-25%);
    }
}

/* -----------------------------------------
  =CSS3 Loading animations
-------------------------------------------- */

.letter-holder {
    padding: 16px;
}

.letter {
    display: inline-block;
    font-size: 1.25rem;
}

/* =Animate the stuff
------------------------ */

.load-6 .letter {
    animation-name: loadingF;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-direction: initial;
}

.l-1 {
    animation-delay: .12s;
}

.l-2 {
    animation-delay: .24s;
}

.l-3 {
    animation-delay: .36s;
}

.l-4 {
    animation-delay: .48s;
}

.l-5 {
    animation-delay: .60s;
}

.l-6 {
    animation-delay: .72s;
}

.l-7 {
    animation-delay: .84s;
}

.l-8 {
    animation-delay: .96s;
}

.l-9 {
    animation-delay: 1.08s;
}

.l-10 {
    animation-delay: 1.2s;
}

.l-11 {
    animation-delay: 1.32s;
}

.l-12 {
    animation-delay: 1.44s;
}

@keyframes loadingF {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*range slider - IE10+*/

input[type=range]::-ms-track {
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;
    /*remove default tick marks*/
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
}

input[type=range]::-ms-fill-upper {
    background: #dddddd;
    border-radius: 10px;
}

input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #FF9933;
}

input[type=range]:focus::-ms-fill-lower {
    background: #888;
}

input[type=range]:focus::-ms-fill-upper {
    background: #cccccc;
}

input[type=range] {
    -webkit-appearance: none;
    background: none;
    outline: none;
}

/*renge slider google chrome*/

input[type=range]::-webkit-slider-runnable-track {
    width: auto;
    height: 5px;
    background: #dddddd;
    border: none;
    border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #FF9933;
    margin-top: -5px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #cccccc;
}

/*range slider - firefox*/

input[type=range]::-moz-range-track {
    width: auto;
    height: 5px;
    background: #dddddd;
    border: none;
    border-radius: 3px;
}

input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #FF9933;
}

/*hide the outline behind the border*/

input[type=range]:-moz-focusring {
    background: none;
}

input[type=range]:focus::-moz-range-track {
    background: #cccccc;
}

/*Animation*/

.flash_content {
    animation: fadeIn linear 1s;
    -webkit-animation: fadeIn linear 1s;
    -moz-animation: fadeIn linear 1s;
    -o-animation: fadeIn linear 1s;
    -ms-animation: fadeIn linear 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/*Responsive design*/

@media screen and (max-device-width: 1326px) {
    .right_side_bar {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .left_side_bar, .LeaderBoard_Add, .BillBoard_Ad {
        display: none;
    }
    .topbar {
        animation: none;
        position: relative;
        z-index: none;
        width: 100%;
    }
    .hide-search-bar {
        animation: none;
    }
    .container {
        padding-top: 0rem;
    }
}

@media (max-width: 1279px) {
    .d-custom-block {
      display: block !important;
    }
    .d-custom-none {
      display: none !important;
    }
  }
  
  @media (min-width: 1280px) {
    .d-custom-block {
      display: none !important;
    }
    .d-custom-none {
      display: block !important;
    }
}

/**These are the style for making searchbar responsive**/

.nav-mobile {
    display: none;
    position: absolute;
    top: -2px;
    left: 0;
    bottom: 4px;
    background: #138808;
    color: #fff;
    height: 35px;
    width: 40px;
}

@media only screen and (max-device-width: 1280px) {
    .multi_select {
        display: none;
    }
    .topbar {
        display: block;
        /* position: absolute; */
        background-color: #138808;
        padding-bottom: 4px;
    }
    .nav-mobile {
        display: list-item;
    }
    .nav-mobile .menu {
        font-size: 22px;
        font-weight: 500;
        margin-left: 45px;
    }
    .collapse_menu {
        margin-top: 10px;
        padding: 10px;
        position: inherit;
        background: white;
        margin: 10px;
        border-radius: 8px;
    }
    .collapse_menu .nav-link {
        font-weight: 700;
    }
    .search_btn {
        padding-left: 180px;
        padding-right: 10px;
    }
    .search_btn .range_input {
        width: 80px;
    }
    #sidebar {
        overflow: scroll;
        position: relative;
    }
    #sidebar a {
        width: 278px;
        text-align: left;
        color: #000;
        border: none;
    }
    #sidebar .nav-link:hover {
        background-color: #FF9933;
        width: auto;
    }
    .filter {
        max-height: 390px;
        padding-bottom: 5px;
        column-width: 280px;
        overflow-x: auto;
        list-style: none;
        scroll-behavior: auto;
    }
    .options_parent_label {
        color: #FF9933;
        padding-top: 4px;
        font-size: 14px;
    }
    .no_options {
        color: #FF9933;
        width: 325px;
        height: auto;
        text-align: center;
    }
}

.label {
    text-align: right;
}

@media screen and (max-width: 768px) {
    .label {
        text-align: left;
    }
    .list_price {
        width: 50%;
    }
    .list_item {
        width: 50%;
    }
}

/** These are the styles for making login and registration page responsive **/

.mobile_viewreport {
    display: none;
}

.responsive-text {
    font-size: 1.5vw; /* This will scale based on viewport width */
    line-height: 1.2; /* Adjust line height to control spacing */
    white-space: pre-line; 
}

@media (max-width: 768px) {
    .responsive-text {
        font-size: 3.5vw; /* Increase font size scaling for smaller screens */
    }
}

@media (max-width: 576px) {
    .responsive-text {
        font-size: 3.2vw; /* Further adjust for very small screens */
    }
}

@media only screen and (max-width: 767px) {
    .container {
        margin: 10px;
    }
    .mobile_viewreport {
        display: block;
    }
    .desktop_viewreport {
        display: none;
    }
}

@media only screen and (max-width: 790px) {
    .profile_table {
        display: none;
    }
}

@media only screen and (max-device-width: 999px) {
    .verify_email {
        display: contents;
    }
}

@media only screen and (max-device-width: 567px) {
    .verify_email_p {
        margin-left: -65px;
    }
}

@media only screen and (max-width: 767px) {
    .weeklabel {
        margin-left: -12px;
    }
}

@media only screen and (max-width: 490px) {
    .profile_table {
        display: none;
    }
}

/*5 columns grid*/

@media(min-width: 1327px) {
    .col-cm-5 {
        width: 20%;
        float: left;
        position: relative;
        min-height: 1px;
        margin-bottom: 10px;
    }
    .col-cm-4 {
        width: 24%;
        float: left;
        position: relative;
        min-height: 1px;
        margin-bottom: 10px;
        padding: 2px;
        margin-left: 8px;
    }

    .col-cm-2 {
        width: 33%;
        float: left;
        position: relative;
        min-height: 1px;
        margin-bottom: 5px;
        padding: 2px;
        margin-left: 2px;
    }
}

.dp_thumb {
    transition: transform .2s;
    border-radius: 50%;
    margin: 0 auto;
}

.dp_thumb:hover {
    transform: scale(2);
}

.addpost_section label {
    margin-top: .5rem;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    color: #000080;
}

.addpost_section [class*="-menu"] {
    z-index: 9;
}

.img_fluid {
    max-width: 353px;
    max-height: 265px;
    width: 100%;
    height: auto;
}

@media screen and (min-device-width: 350px) and (max-device-width: 999px) {
    .mobile_label, .manage_mobile {
        display: contents;
    }
    .desktop_label, .manage_desktop {
        display: none;
    }
}

@media screen and (min-device-width: 360px) and (max-device-width: 639px) {
    .event_job_date {
        width: 315px;
    }
    .col-md-6 {
        width: 100%;
    }
    .col-md-3 {
        width: 35%;
    }
    .col-md-9 {
        width: 65%;
    }
    .listings {
        /* width: 360px; */
        width: 100%;
    }
    .col-cm-4 {
        width: 50%;
        padding: 5px;
    }
    .items_list .listing_card {
        position: relative;
        padding: 0;
        overflow: hidden;
        /* border: 1px solid #cccccc; */
        width: 163.5px;
        height: 298px;
        cursor: pointer;
    }

    .items_list .clip_card, .clip_card {
        position: relative;
        padding: 0;
        overflow: hidden;
        /* width: 163.5px; */
        /* height: 298px; */
        cursor: pointer;
    }

    .listing_thumb_image img {
        object-fit: cover;
        width: 163.5px;
        min-height: 163.5px;
        max-height: 163.5px;
    }
    .listing_thumb_image {
        border-bottom: 1px solid #cccccc;
        width: 163.5px;
        min-height: 163.5px;
        max-height: 163.5px;
    }
    .listing_details {
        padding: 2px;
        min-height: 10px;
    }
    .listing_address {
        font-size: 12px;
        max-height: 85px;
        display: table;
        height: 88px;
    }
    .listing_address .inner_box {
        display: inline-table;
        vertical-align: middle;
        line-height: 16px;
        margin-top: 28px;
    }
    .listing_rating {
        position: absolute;
        bottom: -1px;
    }
    .listing_review_count {
        font-size: 0.50rem;
    }
    .weeklabel {
        width: 170px;
    }
}

@media screen and (min-device-width: 568px) and (max-device-width: 639px) {
    .col-md-3 {
        width: 20%;
    }
    .col-md-9 {
        width: 80%;
    }
    .listings {
        /* width: 562.5px; */
        width: 100%;
    }
    .col-cm-4 {
        width: 33.3%;
        padding: 10px;
    }
    .weeklabel {
        width: 365px;
    }
    .event_job_date {
        width: 509px;
    }
}

@media screen and (min-device-width: 640px) and (max-device-width: 767px) {
    .col-md-3 {
        width: 20%;
    }
    .col-md-9 {
        width: 80%;
    }
    .listings {
        /* width: 600px; */
        width: 100%;
    }
    .col-cm-4 {
        width: 33.3%;
        padding: 4px;
    }
    .items_list .listing_card {
        position: relative;
        padding: 1px;
        overflow: hidden;
        /* border: 1px solid #cccccc; */
        width: 188px;
        height: 348px;
        cursor: pointer;
    }

    .items_list .clip_card, .clip_card {
        position: relative;
        padding: 1px;
        overflow: hidden;
        /* width: 188px; */
        /* height: 348px; */
        cursor: pointer;
    }

    .listing_thumb_image img {
        object-fit: cover;
        width: 200px;
        min-height: 200px;
        max-height: 200px;
    }
    .listing_thumb_image {
        border-bottom: 1px solid #cccccc;
        width: 200px;
        min-height: 200px;
        max-height: 200px;
    }
    .listing_details {
        padding: 2px;
        min-height: 12px;
    }
    .listing_address {
        font-size: 12px;
        max-height: 110px;
        display: table;
        height: 110px;
    }
    .listing_address .inner_box {
        display: inline-table;
        vertical-align: middle;
        line-height: 18px;
        margin-top: 28px;
    }
    .listing_rating {
        position: absolute;
        bottom: -1px;
    }
    .listing_review_count {
        font-size: 0.65rem;
    }
    .weeklabel {
        width: 400px;
    }
    .event_job_date {
        width: 545px;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1326px) {
    .col-md-3 {
        width: 20%;
    }
    .col-md-9 {
        width: 80%;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1326px) {
    .listings {
        /* width: 735px; */
        width: 100%;
    }
    .col-cm-4 {
        width: 33.3%;
        padding: 5px;
    }
    .items_list .listing_card {
        position: relative;
        padding: 1px;
        overflow: hidden;
        /* border: 1px solid #cccccc; */
        width: 233px;
        height: 428px;
        cursor: pointer;
    }
    .items_list .clip_card, .clip_card {
        position: relative;
        /* padding: 1px; */
        overflow: hidden;
        /* width: 233px; */
        /* height: 428px; */
        cursor: pointer;
    }
    .listing_thumb_image img {
        object-fit: cover;
        width: 233px;
        min-height: 233px;
        max-height: 233px;
    }
    .listing_thumb_image {
        border-bottom: 1px solid #cccccc;
        width: 233px;
        min-height: 233px;
        max-height: 233px;
    }
    .listing_details {
        padding: 8px;
        min-height: 12px;
    }
    .listing_title {
        max-height: 2px;
        /*overflow: hidden;*/
        font-size: 18px;
        margin-bottom: 20px;
    }
    .listing_address {
        font-size: 13px;
        max-height: 130px;
        display: table;
        height: 130px;
    }
    .listing_address .inner_box {
        display: inline-table;
        vertical-align: middle;
        line-height: 20px;
        margin-top: 30px;
    }
    .listing_rating {
        position: relative;
        bottom: 0px;
    }
    .listing_review_count {
        font-size: 0.65rem;
    }
}

@media screen and (min-device-width: 1000px) and (max-device-width: 1279px) {
    .col-md-3 {
        width: 40%;
    }
    .col-md-9 {
        width: 60%;
    }
    .listings {
        /* width: 980px; */
        width: 100%;
    }
    .col-cm-4 {
        width: 25%;
        padding: 5px;
    }
}

@media screen and (min-device-width: 1280px) and (max-device-width: 1326px) {
    .listings {
        /* width: 1225px; */
        width: 100%;
    }
    .col-cm-4 {
        width: 20%;
        padding: 5px;
    }
    .content {
        max-width: 1225px;
    }
}

svg {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }
  
  .share-button, .copy-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 2px;
    text-transform: capitalize;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;
    background-color: #FF9933;
  }
  
  .share-button:hover, .copy-link:hover {
    border-color: #FF9933;
  }
  
  .share-button, .copy-link {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .share-button {
    padding-left: 20px;
    padding-right: 20px;
    max-height: 30px;
  }

  .adv-btn {
    margin-top: -10px;
  }
  
  .share-dialog {
    position: absolute;
    top: 81px;
    left: 120.5px;
    transform: translate(-50%, -50%);
    display: none;
    width: 220px;
    height: 120px;
    box-shadow: 0 8px 16px rgba(0,0,0,.15);
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    background-color: #ddd;
  }

  .share {
    position: absolute;
    top: 40px;
    left: 735.5px;
    transform: translate(-50%, -50%);
  }
  
  .share-dialog.is-open {
    display: block;
    z-index: 2;
  }
  
  .targets {
    display: block;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  
  .close-button {
    background-color: transparent;
    border: none;
    padding: 0;
  }
  
  .close-button svg {
    margin-right: -4px;
    margin-top: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 4px;
  }
  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    border-radius: 4px;
    background-color: #eee;
  }
  .copy-link {
      width: 60px;
      font-size: 12px;
  }
  .pen-url {
    margin-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    background-color: #eee;
  }

  .copy {
      color: #eee;
      background-color: #FF9933;
      border-radius: 50%;
      font-size: 11px;
  }
  .hide-text {
      display: none;
      top: -20px;
  }
  .copy:hover + .hide-text {
    display: block;
  }

  .address_margin {
    margin-left: 10px;
  }

  .location_title {
    font-weight: bold;
    text-align: center;
    padding-bottom: 5px;
  }

  .location_p {
    font-size: 14px;
    color: #787878;
    text-align: center;
  }

  .slider_padding {
    padding-top: 40px;
  }

  .location_margin {
    margin-left: 25px;
  }

  .location_padding {
    padding-top: 10px;
  }

  .location_btn {
    margin-left: 20px;
  }

  .payment_margin {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .payment_text {
    text-decoration: underline;
    color: #fff;
  }

  .payment_p {
    font-weight: 500;
  }

  .payment_container_margin {
    margin-top: 40px;
  }

  .payment_list_padding {
    padding: 0px 20px;
  }

  .payment_table {
    color: #000080;
    letter-spacing: 0.5px;
    font-size: 14px;
  }

  .checkbox_color {
    color: #333;
  }

  .popup_height {
    height: 100%;
  }

  .popup_innerheight {
    height: auto;
  }

  .intermediate_popup {
    color: #000080;
    padding: 25px 150px;
    letter-spacing: 0.5px;
    font-size: 16px;
  }

  .intermediate_popup_mobile {
    color: #000080;
    padding: 10px 10px;
    letter-spacing: 0.5px;
    font-size: 16px;
  }

  .intermediate_popup_padding {
    padding: 10px 5px;
  }

  .popup_padding {
    padding: 0px 10px;
  }

  .intermediate_popup_btn {
    letter-spacing: 0.5px;
    width: 100%;
  }

  .popup_table {
    margin-bottom: 21px;
  }

  .popup_upgrade {
    letter-spacing: 0.5px;
    padding: 25px 180px;
    font-size: 16px;
    color: #000080;
  }

  .popup_upgrade_mobile {
    padding: 10px 20px;
    font-size: 14px;
    color: #000080;
  }

  .popup_upgrade_padding {
    padding-left: 60px;
  }

.billBoard {
    display: none;
}

@media screen and (min-device-width: 1000px) {
    .large_rect {
        display: none;
    }

    .billBoard {
        display: block;
    }
}

.registration_padding {
    margin-top: 10px;
}

.business_hide, .regular_hide {
    border: solid black 1px;
    padding-left: 2px;
    padding-right: 2px;
    background-color: #fff;
    border-radius: 5px;
    display: none;
    font-size: 12px;
    margin-left: 160px;
    
}

.business_hide {
    transform: translate(32%, -50%);
}

.regular_hide {
    transform: translate(30%, -5%);
}

.business:hover + .business_hide, .regular:hover + .regular_hide {
    display: block;
    position: absolute;
}

.promoter_radio {
    margin-bottom: 20px;
}
.business_hide_mobile, .regular_hide_mobile {
    border: solid black 1px;
    /* padding-left: 2px;
    padding-right: 2px; */
    padding: 5px;
    background-color: #fff;
    border-radius: 5px;
    display: none;
    line-height: 12px;
    font-size: 10px;
    margin-left: 70px;
    
}

.business_hide_mobile {
    transform: translate(-12%, -18%);
}

.regular_hide_mobile {
    transform: translate(-12%, -20%);
}

.business:hover + .business_hide_mobile, .regular:hover + .regular_hide_mobile {
    display: block;
    position: absolute;
}

.listing_spacing {
    margin-left: 10px;
}

.searchSlider_admin {
    width: 160px;
    border: solid 1px #FF9933;
    transform: translate(-50%, 10%);
}

.button_on_admin {
    position: absolute;
    top: 2px;
    left: -2px;
    right: 0;
    bottom: 0;
    font-size: .650rem;
    color: #FF9933;
}

.searchRound_admin .slider:before {
    left: 4px;
    bottom: 0.5px;
}

.searchRound_admin input:checked+.slider:before {
    transform: translateX(130px);   
}

.searchSlider {
    width: 128px;
    border: solid 1px #FF9933;
    transform: translate(-5%, 20%);
}

.searchRound input:checked+.slider:before {
    transform: translateX(100px);
    
}

.searchRound .slider:before {
    left: 4px;
    bottom: 0.5px;
}

.mobile_keyword {
    padding-top: 4px;
}

.mobile_searchSlider {
    width: 128px;
    border: solid 1px #FF9933;
    transform: translate(-15%, 35%);
}

.mypages_editor {
    padding-bottom: 50px;
}

.withdrawl_section {
    margin-left:70px;
}

.withdrawl_section_span {
    color: red;
}

.withdraw_btn {
    padding: 4px 35px 4px 35px;
    border-radius: 10px;
}

.view_table_input {
    margin-top: 20px;
    padding: 4px 35px 4px 35px;
    width: 280px;
    height: 40px;
    border: 2px solid black;
    text-align: center;
    font-size: 18px;
}

.view_table_input_submit {
    margin-top: 12px;
    padding: 4px 12px 4px 12px;
    border-radius: 10px;
    margin-left: 200px;
}

.referral_act {
    margin: 15px 0;
}

.referral_act_date {
    margin-left: 40px;
}

@media screen and (min-device-width: 360px) and (max-device-width: 567px) {
    .col-cm-2 {
        width: 100%;
        margin-bottom: 2px;
        padding: 0px;
        margin-left: 12px;
    }
}

@media screen and (min-device-width: 568px) and (max-device-width: 639px) {
    .col-cm-2 {
        width: 100% !important;
        margin-bottom: 2px;
        padding: 0px;
        padding-left: 20%;
        padding-right: 20%;
    }
}

@media screen and (min-device-width: 640px) and (max-device-width: 767px) {
    .col-cm-2 {
        width: 50% !important;
        margin-bottom: 2px;
        margin-left: 0px;
        padding-left: 5px;
    }

}

@media screen and (min-device-width: 768px) and (max-device-width: 999px) {
    .col-cm-2 {
        width: 50% !important;
        margin-bottom: 2px;
        margin-left: 0px;
        padding-left: 3%;
        padding-right: 5%;
    }
}

@media screen and (min-device-width: 1000px) and (max-device-width: 1279px) {
    .col-cm-2 {
        width: 33% !important;
        margin-bottom: 2px;
        padding-left: 8px;
    }
}

@media screen and (min-device-width: 1280px) and (max-device-width: 1326px) {
    .col-cm-2 {
        margin-bottom: 2px;
        padding: 5px;
    }
}

.modal-dialog {
    padding: 8px;
    margin-top: 168px;
    background-color:#e7f3e6;
    scroll-behavior: smooth;
    overflow: scroll;
    overflow-y: scroll;
    position: fixed;
    display: block;
    top: 0;
    bottom: 0;
    right: -350px;
    width: 350px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }
  
  .modal-dialog.show {
    right: 0px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  .notifyNum {
    font-size: 10px;
    left: -10px;
    color: red;
    border-radius: 100%;
    position: relative;
    padding: 2px;
  }

  .notificationList {
    padding: 2px;
  }

  .profilePopupImg {
    padding: 10px;
    padding-bottom: 20px;
  }

  .profilePopupImgMob {
    margin-top: -5px;
  }

  .qualify {
    color: #138808;
    font-size: 22px;
    padding: 20px;
  }

  .locAdd{
    color: #FF9933;
    padding-left: 160px;
    padding-right: 150px;
    font-size: 16px;
  }

  .scrolling-top_bar {
    position: sticky;
    top: 2px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    color: #FF9933;
  }

  .scrolling-topbar {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #138808;
    background-color: #e7f3e6;
  }
  
  .scrolling-message {
    display: inline-flex;
    animation: scrollText linear infinite;
    font-weight: 700;
    font-size: 16px;
  }
  
  @keyframes scrollText {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .homecard {
    color: #FF9933;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
}

.password-toggle {
    cursor: pointer;
    font-size: 1.1rem;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    color: #777;
}

.listingTable {
    scroll-behavior: smooth;
    overflow: scroll;
    overflow-y: scroll;
}

.loyalty {
    border: 1px solid #333;
    border-radius: 2px;
}

/************** Clip Video Css ****************/

/* .clip_ad {
    transform: translate(0%, 60%);
}

.mobile_clip_ad {
    position: absolute;
    transform: translate(0%, 65%);  
} */

.nextBtn {
   top: 45vh;
   height: fit-content;
}

.prevNext {
    text-decoration: none;
    display: inline-block;
    padding: 2px 16px;
    border-radius: 50%;
    color: #fff;
    font-size: 36px;
    background-color: #000080;
  }
  
  .prevNext:hover {
    color: #FF9933;
  }

/* .video-container {
    position: sticky;
} */

/* .video-container-mob {
    position: relative;
    margin-bottom: 20px;
    transition: 'transform 0.6s ease';
} */

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 28px;
}

.home_link {
    position: absolute;
    color: #FF9933;
    font-size: 27px;
    padding: 5px;
}

.clip-button {
    position: absolute;
    color: #fff;
    letter-spacing: 1px;
    cursor: pointer;
    text-align: right;
    /* padding-left: 70px; */
}

.clip {
    bottom: 5px;
}

.watch-button {
    position: absolute;
    color: #fff;
    letter-spacing: 1px;
    cursor: pointer;
    text-align: left;
}

.button-container {
    position: absolute;
    bottom: 25px;
    right: 25px;
   
  }

.clip-button-mob {
    cursor: pointer;
    position: absolute;
    transform: translate(-90%, -250%);
}

.watch-button-mob {
    transform: translate(-90%, -120%);
    position: absolute;
}

.styled-icon {
    cursor: pointer;
    z-index: 10;
    font-size: xx-large;
    color: #ccc;
    -webkit-text-stroke: 1px #FF9933; /* For Webkit browsers (Chrome, Safari) */;
}

#myVideo {
    height: 91vh;
    width: 91vh;
    padding-top: 2%;
    border-radius: 15px;
    text-align: center;
    border-radius: 2px solid blue;
}

.clipHeader {
    padding-top: 2px;
}

.clip_video {
    text-align: center;
    /* height: 100%; */
    width: 100%;
    height: 91vh;
}

.clipAd_container {
    position: relative;
    display: inline-block;
}

.video-container-mob {
    position: relative;
    margin-bottom: 20px;
    transition: 'transform 0.6s ease';
    display: block;
}

.video-container {
    position: relative;
    display: inline-block;
  }
  
  .video-container video, .video-container-mob video {
    width: 100%;
    height: auto;
  }
  
  .play-pause-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .video-container:hover .play-pause-button, .video-container-mob:hover .play-pause-button {
    opacity: 10;
  }
  
  .sharedialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to bottom, #FF9933 5%, #138808 50%);
    border: 1px solid #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    z-index: 9999;
  }

.sharedialog h2 {
    margin-bottom: 10px;
    font-weight: 600;
}

.sharedialog .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .sharedialog input[type="text"] {
    width: calc(100% - 42px); /* Adjust width as needed */
    padding: 5px;
    border: 1px solid #fff;
    border-radius: 5px;
  }

.copylink {
    width: 25px;
    height: 32px;
    margin: 3px;
    background-color: #fff;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 5px;
    -webkit-text-stroke: 1px #FF9933;
}

.sharebuttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.sharebutton {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-size: cover;
    cursor: pointer;
}

.fb {
    background-image: url('./assets/facebook.png');
}

.whatsapp {
    background-image: url('./assets/social.png');
}

.twitter {
    background-image: url('./assets/twitter.png');
}

.skype {
    background-image: url('./assets/skype.png');
}

.telegram {
    background-image: url('./assets/telegram.png');
}

.snapchat {
    background-image: url('./assets/snapchat.png');
}

.linkedin {
    background-image: url('./assets/linkedin.png');
}

.gmail {
    background-image: url('./assets/gmail.png');
}

.shareclose {
    text-align: center;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    color: #fff;
    border: 1px solid #FF9933;
    background-color: #FF9933;
    font-weight: 700;
}

.diwali-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
  }
  
  /* Popup Container with Gradient Border */
  .diwali-popup {
    background-color: #000080; /* Navy blue */
    color: #fff;
    border-radius: 15px;
    padding: 20px;
    max-width: 400px;
    text-align: center;
    position: relative;
    animation: popupZoomIn 0.5s ease;
    border: 2px solid transparent;
    background-image: linear-gradient(#000080, #000080), radial-gradient(circle, #FF9933, #138808);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  
  /* Fireworks Effect */
  @keyframes fireworks {
    0%, 100% { opacity: 0; transform: scale(0.5); }
    50% { opacity: 1; transform: scale(1.5); }
  }
  
  .firework {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff9933;
    animation: fireworks 1s infinite ease-in-out alternate;
  }
  
  /* Positioning Fireworks */
  .firework1 { top: -20px; left: 30%; }
  .firework2 { top: 10%; right: 20%; }
  .firework3 { bottom: 10%; left: 25%; }
  
  /* Flickering Diya Animation */
  @keyframes flicker {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
  
  .diya {
    background: url('assets/diya2.png') no-repeat center center; /* Use a small diya image */
    background-size: cover;
    display: inline-block;
    animation: flicker 1.8s infinite ease-in-out;
  }
  
  /* Button Styling */
  .diwali-popup button {
    background-color: #ff9933; /* Orange */
    color: #000080; /* Navy blue */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .diwali-popup button:hover {
    background-color: #00cc44; /* Green */
    color: white;
  }
  
  /* Popup Zoom Animation */
  @keyframes popupZoomIn {
    from { transform: scale(0); }
    to { transform: scale(1); }
  }

  /* Glowing Text Animation for Heading */
.diwali-message {
    font-size: 1.8em;
    color: #ffd700; /* Golden yellow */
    animation: glow 1.5s infinite alternate, textBounce 1.2s infinite;
  }
  
  /* Submessage with Gradient Animation */
  .diwali-submessage {
    font-size: 1em;
    background: linear-gradient(to right, #FF9933, #138808);
    -webkit-background-clip: text;
    color: transparent;
    animation: gradientMove 3s linear infinite;
  }
  
  /* Glowing Effect */
  @keyframes glow {
    0% { text-shadow: 0 0 5px #FF9933, 0 0 10px #ff9933, 0 0 15px #ffd700, 0 0 20px #ffd700; }
    100% { text-shadow: 0 0 10px #FF9933, 0 0 20px #ff9933, 0 0 30px #ffd700, 0 0 40px #ffd700; }
  }
  
  /* Text Bouncing Animation */
  @keyframes textBounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
  }
  
  /* Moving Gradient Effect */
  @keyframes gradientMove {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
  }
  
  /* Close Icon Styling */
.close-icon {
    position: absolute;
    top: -10px;
    right: 10px;
    font-size: 24px;
    color: #ffd700; /* Golden color */
    cursor: pointer;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .close-icon:hover {
    color: #ff9933; /* Orange color on hover */
  }

  .staff-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5); /* Add a background overlay */
    overflow: hidden;
}

.popup-content {
    background: #fffaf5;
    padding: 20px;
    width: 500px;
    max-height: 90vh; /* Restrict height to viewport */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto; /* Enable scrolling within popup */
}

.popup-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}


  .secondary {
    background: #FF9933;
    color: #fff;
  }
  
  .secondary:hover {
    background: #138808;
    color: #fff;
  }

  .popup-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    background-color: #ff9933;
}

.follow {
    padding: 10px;
}

.fass {
    padding: 10px;
    font-size: 22px;
    width: 44px;
    text-align: center;
    text-decoration: none;
    margin: 2px 25px;
    border-radius: 50%;
  }

  .fasLogo {
    width: 42px;
    text-align: center;
    text-decoration: none;
    margin: 2px 24px;
    border-radius: 50%;
  }
  
  .fa:hover {
      opacity: 0.7;
  }

  .fasLogo:hover {
    opacity: 0.7;
  }
  
  .fa-facebook {
    background: #3B5998;
    color: white;
  }
  
  .fa-linkedin {
    background: #007bb5;
    color: white;
  }
  
  .fa-youtube {
    background: #bb0000;
    color: white;
  }

  @media screen and  (min-width: 400px) and (max-width: 700px) {
    .follow {
        padding: 2px;
        font-size: 8px;
    }
    
    .fass {
        padding: 7px;
        width: 36px;
        text-align: center;
        text-decoration: none;
        margin: 2px 8px;
        border-radius: 50%;
      }

      .fasLogo {
        width: 36px;
        margin: 2px 4px;
        border-radius: 50%;
      }

      .follow {
        padding: 8px;
        font-size: 10px;
    }
}

@media screen and (max-width: 400px) {
    .fasLogo {
        width: 36px;
        margin: 2px 4px;
        border-radius: 50%;
      }

      .fass {
        padding: 6px;
        width: 36px;
        text-align: center;
        text-decoration: none;
        margin: 2px 5px;
        border-radius: 50%;
      }

      .follow {
        padding: 4px;
        font-size: 8px;
      }
}
